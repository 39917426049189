<template>
  <div class="pb-5">
    <h5 class="primary--text fw-700 font-14px text-center mb-6">
      当日売上の入力について
    </h5>
    <div class="font-12px text-333 line-height-15">
      <p>
        1日全体の金額を入力することで、以下のようにディナー売上の数字を計算します。
      </p>
      <div class="light-yellow mx-3 py-2 px-1 my-5 text-center">
        ( 当日売上 ) ー ( ランチ売上 ) ＝ ( ディナー売上 )
      </div>
      <p>
        数字の入力後、「保存するボタン」を押すと、計算結果のディナー売上の数字が保存されます。
      </p>
      <p>
        ※ランチとディナーの日報をどちらも作成する必要がある場合、
        <span class="fw-700 underlined">
          ランチ売上を先に報告するか、ランチ売上と当日売上を同時に報告してください。</span
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "SameDayModal",
};
</script>
